<script setup lang="ts" generic="T extends keyof GA4Events">
import { GA4Events } from "nuxt-ga4"

const props = withDefaults(
  defineProps<{
    eventKey: string
    event: T
    payload: Omit<GA4Events[T], "event">
    lazy?: boolean
  }>(),
  {
    lazy: false
  }
)

const { payload } = toRefs(props)
const { event, sendEvent } = useGAEvent(props.eventKey, props.event)
event.value = { event: props.event, ...payload.value }

watch(payload, (value) => {
  event.value = { event: props.event, ...value }
})

onMounted(() => {
  if (!props.lazy) {
    sendEvent({ clearEcommerce: true })
  }
})

defineExpose({
  sendEvent
})
</script>

<template>
  <slot />
</template>
